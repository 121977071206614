<template>
  <div>
    <img :id="shareIconId" width="40" height="40" :src="imagePath" @mouseover="hover=true" @mouseleave="hover=false" :alt="shareIconId">
</div>
</template>

<script>

  export default {
    name: "ShareIcon",
    data() {
      return {
        hover: false,
      }
    },
    computed: {
      imagePath() {
        if (this.hover == true) {
          return require(`../assets/share_${this.shareIconId}_hover.svg`);
        } else {
          return require(`../assets/share_${this.shareIconId}.svg`);
        }
      }
    },
    props: {
      shareIconId: {
        type: String,
        default: '',
        required: true
      }
    }
  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@mds/constants';
  @import '@mds/fonts';
  @import '@mds/typography';
  @import '@mds/link';

  /*img {
    display: block;
    max-width: 100%;
  }*/

</style>
